import { Grid } from '@lemonenergy/lemonpie'
import styled from 'styled-components'

const Html = styled.html`
  ${Grid.Container} {
    width: 100%;
  }
`

export default Html
